<template>
  <Modal ref="modalRef" width="100%" :full-screen="true" @on-show-update="handleShowUpdate">
    <iframe ref="iframe" :srcdoc="propsReactive.iframeSource" class="sr-iframe" />
  </Modal>
</template>

<script setup lang="ts">
import type Modal from '../../base/components/Modal.vue';

const modalRef = ref<InstanceType<typeof Modal> | null>(null);
const iframe = ref<InstanceType<typeof HTMLIFrameElement> | null>(null);

const { isFullscreen, enter, exit } = useFullscreen(iframe);

const openModal = () => {
  modalRef.value?.openModal();
  enter();
};

const closeModal = () => {
  exit();
  modalRef.value?.closeModal();
};

const reloadIframe = () => {
  iframe.value?.contentWindow?.location.reload();
};

defineExpose({ openModal, closeModal, reloadIframe });

const props = defineProps<{
  title: string;
  iframeSource: string;
}>();
const propsReactive = reactive(props);

const emits = defineEmits<{
  (e: 'onShowUpdate', value: boolean): void;
}>();

function handleShowUpdate(value: boolean): void {
  if (!value && isFullscreen) {
    exit();
  }
  emits('onShowUpdate', value);
}
</script>

<style scoped lang="scss"></style>
