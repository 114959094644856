import type {
  AddInvoiceDto,
  AddInvoiceRefundDto,
  InvoiceDocumentStatusDto,
  InvoiceDto,
  InvoiceListDto,
  UpdateInvoiceDto,
  UpdateInvoiceRefundDto,
} from '../../base/common/dtos/invoice-list.dto';
import { InvoiceType } from '../../base/common/enums/invoice-type';
import type { BaseDataTableUrlParamsModel } from '../../base/common/models/base/base-data-table-url-params.model';
import { ReportType, type ExportReportParams } from '../../base/common/models/export/export.model';

interface State {
  loading: boolean;
  invoices: InvoiceListDto;
  signatures: InvoiceListDto;
  selectedInvoiceId: number | null;
  selectedInvoiceType: InvoiceType | null;
  selectedInvoice: InvoiceDto | null;
  signNowIframeSource: string | null;
  invoiceDocumentStatus: Record<InvoiceDto['id'], InvoiceDocumentStatusDto | null>;
  selectedInvoiceForDocumentStatus: number | null;
  lastAddedInvoiceId: number | null;
}

export interface FetchInvoicesListParams extends BaseDataTableUrlParamsModel<InvoiceDto> {}

export const useInvoicesStore = defineStore('invoicesStore', {
  state: (): State => ({
    loading: false,
    invoices: {
      data: [],
      total: 0,
    },
    signatures: {
      data: [],
      total: 0,
    },
    selectedInvoiceId: null,
    selectedInvoiceType: null,
    selectedInvoice: null,
    signNowIframeSource: null,
    invoiceDocumentStatus: {},
    selectedInvoiceForDocumentStatus: null,
    lastAddedInvoiceId: null,
  }),
  actions: {
    async skipSignNowEdit(tutorial = 'skipSignNowEditPdf') {
      const { $snapApi } = useNuxtApp();
      const { getSession } = useAuth();

      try {
        this.loading = true;

        const data = {
          [tutorial]: true,
        };

        await $snapApi({ method: 'post', url: 'tutorials/skip-signnow-edit', data });
        getSession({ force: true, required: true });
      } catch {
      } finally {
        this.loading = false;
      }
    },
    async fetchInvoicesList(options?: FetchInvoicesListParams): Promise<void> {
      const { $snapApi } = useNuxtApp();

      this.loading = true;

      let url = 'invoices/datatable';

      if (options) {
        url = appendUrlDataTableParamsT(url, options);
      }

      try {
        const response = await $snapApi({
          method: 'GET',
          url,
        });

        this.invoices.data = response.data.list;
        this.invoices.total = response.data.count;
      } catch {
      } finally {
        this.loading = false;
      }
    },
    async fetchReport(params: ExportReportParams): Promise<void> {
      const { $snapApi, $toastError, $toastSuccess } = useNuxtApp();

      this.loading = true;

      try {
        const response = await $snapApi({
          method: 'GET',
          url: params.url,
          responseType: 'blob',
        });

        triggerDownloadFile(response, params.filename);

        $toastSuccess(params.successMessage);
      } catch (error) {
        $toastError(params.errorMessage);
      } finally {
        this.loading = false;
      }
    },
    async sendInvoiceRemind(invoiceId: number) {
      const { $snapApi, $toastError, $toastSuccess } = useNuxtApp();

      this.loading = true;

      const url = `invoices/send-remind/${invoiceId}`;

      try {
        await $snapApi({
          method: 'POST',
          url,
        });

        $toastSuccess('Sending the reminder was successful!');
      } catch {
        $toastError('Sending a reminder failed!');
      } finally {
        this.loading = false;
      }
    },
    async getInvoicesExportReportParams(
      reportType: ReportType,
      options?: FetchInvoicesListParams,
    ): Promise<ExportReportParams> {
      const baseParams = {
        type: reportType,
        options: options,
        successMessage: `${reportType.toUpperCase()} report successfully downloaded!`,
        errorMessage: `Failed to load an ${reportType.toUpperCase()} report!`,
      };

      const url = 'export/report';

      const reportSettings = {
        [ReportType.CSV]: {
          urlPath: 'invoices-csv',
          filename: 'invoices-report.csv',
        },
        [ReportType.EXCEL]: {
          urlPath: 'invoices-excel',
          filename: 'invoices-report.xlsx',
        },
        [ReportType.PDF]: {
          urlPath: 'invoices-pdf',
          filename: 'invoices-report.pdf',
        },
      };

      const { urlPath, filename } = reportSettings[reportType];

      const fullUrl = options ? appendUrlDataTableParamsT(`${url}/${urlPath}`, options) : `${url}/${urlPath}`;

      const fetchReportParams: ExportReportParams = {
        ...baseParams,
        url: fullUrl,
        filename: filename,
      };

      return fetchReportParams;
    },
    async removeSelectedInvoice(): Promise<void> {
      const { $snapApi } = useNuxtApp();

      this.loading = true;

      const url = `invoices/${this.selectedInvoiceId}`;

      try {
        await $snapApi({
          method: 'DELETE',
          url,
        });
      } catch {
      } finally {
        this.loading = false;
        this.selectedInvoiceId = null;
      }
    },
    async downloadInvoiceCopy(id: number): Promise<void> {
      const { $snapApi, $toastSuccess, $toastError } = useNuxtApp();

      this.loading = true;

      try {
        const url = `export/report/${id}/export-copy`;

        const response = await $snapApi({
          method: 'GET',
          url,
          responseType: 'blob',
        });

        triggerDownloadFile(response, 'invoice-copy.pdf');

        $toastSuccess(`${ReportType.PDF.toUpperCase()} report successfully downloaded!`);
      } catch {
        $toastError(`Failed to load an ${ReportType.PDF.toUpperCase()} report!`);
      } finally {
        this.loading = false;
      }
    },
    async downloadAttachment(id: number) {
      const { $snapApi } = useNuxtApp();

      try {
        const url = `invoices/${id}/attachment`;

        const response = await $snapApi({
          method: 'GET',
          url,
          responseType: 'blob',
        });

        triggerDownloadFile(response, 'Attachment.pdf');
      } catch {
      } finally {
      }
    },
    async fetchInvoiceDocumentStatus() {
      const { $snapApi } = useNuxtApp();

      const url = `invoices/${this.selectedInvoiceForDocumentStatus}/document-status`;

      this.loading = true;

      try {
        const response = await $snapApi({
          method: 'GET',
          url,
        });
        if (this.selectedInvoiceForDocumentStatus) {
          this.invoiceDocumentStatus[this.selectedInvoiceForDocumentStatus] = response.data;
        }
      } catch {
      } finally {
        this.loading = false;
      }
    },
    async saveNewInvoice(model: AddInvoiceDto): Promise<void> {
      const body = toInvoiceBody(model);

      const { $snapApi, $toastError, $toastSuccess } = useNuxtApp();

      this.loading = true;

      const url = 'invoices';

      try {
        const { data }: { data: InvoiceDto } = await $snapApi({
          method: 'POST',
          url,
          data: body,
        });

        // TODO: update after new Signatures functionality release
        $toastSuccess(model.carrierId ? 'A new Invoice is saved' : 'A new document is created');

        this.selectedInvoiceId = data.id;
      } catch {
        $toastError('Failed to save a new Invoice');
      } finally {
        this.loading = false;
      }
    },
    async saveExistingInvoice(model: UpdateInvoiceDto) {
      const body = toInvoiceBody(model);

      const { $snapApi, $toastError, $toastSuccess } = useNuxtApp();

      this.loading = true;

      const url = `invoices/${this.selectedInvoiceId}`;

      try {
        await $snapApi({
          method: 'PUT',
          url,
          data: body,
        });

        // TODO: update after new Signatures functionality release
        $toastSuccess(model.carrierId ? 'An existing Invoice is saved' : 'Document is updated');
      } catch {
        $toastError('Failed to save an Invoice');
      } finally {
        this.loading = false;
      }
    },
    async toggleAutoRemitInvoiceStatus(invoiceId: number, newIsAutoRemitStatus: boolean) {
      const { $snapApi, $toastError, $toastSuccess } = useNuxtApp();

      this.loading = true;

      const url = `invoices/toggle-auto-remit/${invoiceId}`;

      try {
        await $snapApi({
          method: 'PUT',
          url,
          data: { isNewAutoRemitPremiums: newIsAutoRemitStatus },
        });

        $toastSuccess('An existing Invoice auto remit field is saved');
      } catch {
        $toastError('Failed to toggle auto remit for invoice');
      } finally {
        this.loading = false;
      }
    },
    async sendDirectPayment(invoiceId: number) {
      const { $snapApi, $toastError, $toastSuccess } = useNuxtApp();

      this.loading = true;

      const url = `invoices/direct-payment/${invoiceId}`;

      try {
        await $snapApi({
          method: 'POST',
          url,
        });

        $toastSuccess('The request to automatically send a paper check has been made!');
      } catch {
        $toastError('Failed to automatically send a paper check request!');
      } finally {
        this.loading = false;
      }
    },
    async fetchSelectedInvoice(): Promise<void> {
      if (!this.selectedInvoiceId) {
        return;
      }

      const { $snapApi } = useNuxtApp();

      this.loading = true;

      let url;

      if (this.selectedInvoiceType === InvoiceType.Invoice) {
        url = `invoices/${this.selectedInvoiceId}`;
      } else {
        url = `invoices/refunds/${this.selectedInvoiceId}`;
      }

      try {
        const response = await $snapApi({
          method: 'GET',
          url,
        });

        this.selectedInvoice = response.data;
      } catch {
      } finally {
        this.loading = false;
      }
    },
    async saveNewInvoiceRefund(model: AddInvoiceRefundDto) {
      const body = toInvoiceRefundBody(model);

      const { $snapApi, $toastSuccess } = useNuxtApp();

      this.loading = true;

      const url = 'invoices/refunds';

      try {
        await $snapApi({
          method: 'POST',
          url,
          data: body,
        });

        $toastSuccess('A new Invoice Refund is saved');
      } catch {
      } finally {
        this.loading = false;
      }
    },
    async saveExistingInvoiceRefund(model: UpdateInvoiceRefundDto) {
      const body = toInvoiceRefundBody(model);

      const { $snapApi, $toastSuccess } = useNuxtApp();

      this.loading = true;

      const url = `invoices/refunds/${this.selectedInvoiceId}`;

      try {
        await $snapApi({
          method: 'PUT',
          url,
          data: body,
        });

        $toastSuccess('An existing Invoice Refund is saved');
      } catch {
      } finally {
        this.loading = false;
      }
    },
    async fetchSignNowIframeSource(isSigning: boolean) {
      const { $snapApi } = useNuxtApp();

      this.loading = true;

      const url = `invoices/${this.selectedInvoiceId}/${isSigning ? 'signing' : 'editor'}`;

      try {
        const response = await $snapApi({
          method: 'GET',
          url,
        });

        this.signNowIframeSource = response.data;
      } catch {
      } finally {
        this.loading = false;
      }
    },
    async fetchSignaturesList(options?: FetchInvoicesListParams): Promise<void> {
      const { $snapApi } = useNuxtApp();

      this.loading = true;

      let url = 'invoices/datatable?signing-only=true';

      if (options) {
        url = appendUrlDataTableParamsT(url, options);
      }

      try {
        const response = await $snapApi({
          method: 'GET',
          url,
        });

        this.signatures.data = response.data.list;
        this.signatures.total = response.data.count;
      } catch {
      } finally {
        this.loading = false;
      }
    },
  },
});

function toInvoiceBody(model: AddInvoiceDto | UpdateInvoiceDto) {
  if (model.attachment) {
    const body = new FormData();

    body.append('policyholderId', model.policyholderId?.toString() ?? '');
    body.append('carrierId', model.carrierId?.toString() ?? '');
    body.append('grossPremium', model.grossPremium?.toString() ?? '');
    body.append('commission', model.commission?.toString() ?? '');
    body.append('netPremium', model.netPremium?.toString() ?? '');
    body.append('memo', model.memo ?? '');
    body.append('policyId', model.policyId ?? '');
    body.append('brokerCode', model.brokerCode ?? '');
    body.append('file', model.attachment);
    body.append('taxes', model.taxes?.toString() ?? '');
    body.append('serviceFee', model.serviceFee?.toString() ?? '');
    body.append('shouldBeSigned', model.shouldBeSigned.toString());
    body.append('isAutoRemitPremiums', model.isAutoRemitPremiums.toString());
    body.append('numDaysForAutoRemind', model.numDaysForAutoRemind.toString());
    body.append('mgaId', model.mgaId?.toString() ?? '');
    body.append('effectiveDate', timestampToIso(model.effectiveDate) ?? '');
    body.append('expirationDate', timestampToIso(model.expirationDate) ?? '');
    body.append('isCommissionVisibleForPolicyholder', model.isCommissionHiddenToPolicyholder.toString());

    return body;
  } else {
    return {
      ...model,
      isCommissionVisibleForPolicyholder: model.isCommissionHiddenToPolicyholder,
    };
  }
}

function toInvoiceRefundBody(model: AddInvoiceRefundDto | UpdateInvoiceRefundDto) {
  if (model.attachment) {
    const body = new FormData();

    body.append('policyholderId', model.policyholderId?.toString() ?? '');
    body.append('netPremium', model.netPremium.toString());
    body.append('memo', model.memo ?? '');
    body.append('file', model.attachment);

    return body;
  } else {
    return model;
  }
}
